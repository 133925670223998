    import React from 'react';
    import { useBingo } from './../context/BingoContext';

    const BolasSorteadas = () => {
        const { numerosSorteados } = useBingo();

        // const numerosSorteadosFlat = numerosSorteados.flatMap(array => array[0]);

        // Array de números de 1 a 90
        const numeros = Array.from({ length: 90 }, (_, index) => index + 1);

        return (
            <div style={{ display: 'flex',height: '100%', flexWrap: 'wrap', gap: '1px', padding: '0px', justifyContent: 'center', left: 1 }}>
                {numeros.map(numero => (
                    <div key={numero} style={{
                        width: '9.7%',
                        marginLeft: '0%',
                        height: '10%',
                        border: '2px solid #546E3D',    
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: numerosSorteados.includes(numero) ? 'green' : 'transparent', // Muda a cor se o número estiver nos sorteados
                        fontSize: '1.2rem',
                    }}>
                        <h3>{numero < 10 ? `0${numero}` : numero}</h3>
                    </div>
                ))}
            </div>
        );
    }

    export default BolasSorteadas;
