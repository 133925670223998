import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import { useBingo } from './../../context/BingoContext';
import './proximaRodada.css';

const ProximaRodada = () => {
  const { anuncios } = useBingo();
  const [currentIndex, setCurrentIndex] = useState(0);

  const formatarParaMonetario = (valor) => {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  useEffect(() => {
    // Define a função para avançar para o próximo anúncio
    const nextAnuncio = () => {
      setCurrentIndex((prevIndex) => {
        // Verifica se há mais anúncios disponíveis
        if (prevIndex + 1 < anuncios.length) {
          // Avança para o próximo anúncio
          return prevIndex + 1;
        } else {
          // Se não houver mais anúncios, volta para o primeiro
          return 0;
        }
      });
    };

    // Define o tempo de exibição do anúncio atual
    const currentAnuncio = anuncios[currentIndex];
    const tempo = currentAnuncio ? currentAnuncio.tempo * 1000 : 0; // Tempo em milissegundos
    const timer = setTimeout(nextAnuncio, tempo);

    // Limpa o temporizador quando o componente é desmontado ou quando o próximo anúncio é exibido
    return () => clearTimeout(timer);
  }, [anuncios, currentIndex]);

  const [time, setTime] = useState('14:00');
  const [remainingTime, setRemainingTime] = useState('');

  // Função para calcular o tempo restante até 14:00
  const calculateRemainingTime = () => {
    const currentTime = new Date();
    const targetTime = new Date();
    targetTime.setHours(14);
    targetTime.setMinutes(0);
    targetTime.setSeconds(0);

    let difference = targetTime.getTime() - currentTime.getTime();

    // Verificar se já passou das 14:00
    if (difference < 0) {
      difference += 24 * 60 * 60 * 1000; // Adiciona um dia se o horário já passou
    }

    // Converter a diferença para horas, minutos e segundos
    const hours = Math.floor(difference / (60 * 60 * 1000));
    const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);

    // Formatar o tempo restante
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    setRemainingTime(formattedTime);
  };

  // Atualizar o tempo restante a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: '20vw', height: '100vh' }} >
      <div>
        <head>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap" rel="stylesheet" />
          {/* Ícones do FlatIcon */}
        </head>

        <div className="header1">Proxima rodada</div>

        <div className="card1">
          <div className="wrapper">
            <div className="display">
              <div id="time">{remainingTime}</div>
            </div>
            <span></span>
            <span></span>
          </div>

        </div>

        <div className="card1">
          <div className="item1">
            <center>
              <h3>DOAÇÕES</h3>
              <p>10 Points</p>
              {/* <p><span style={{ lineHeight: '14px' }}><img src="https://i.imgur.com/00gUeNo.png" alt="Complete" /> Complete</span></p> */}
            </center>
          </div>
        </div>

        <div className="card1">
          <div className="item1">
            <center>
              <h3>Kuadra</h3>
              <p>10 Points</p>
              {/* <p><span style={{ lineHeight: '14px' }}><img src="https://i.imgur.com/00gUeNo.png" alt="Complete" /> Complete</span></p> */}
            </center>
          </div>
          <div className="item2">
            <center>
              <h3>Kina</h3>
              <p>30 Points</p>
            </center>
          </div>
          <div className="item3">
            <center>
              <h3>Keno</h3>
              <p>20 Points</p>
            </center>
          </div>
          <div className="item4">
            <center>
              <h3>Acumulado</h3>
              <p>$1000,00</p>
            </center>
          </div>

        </div>
      </div>


    </div>
  )
}
export default ProximaRodada;
