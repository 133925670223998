import React from 'react';
import './PremioSorteio.css'; 
import { useBingo } from './../context/BingoContext';


const PremioSorteio = () => {
    const { premios, primeiroPremio, segundoPremio, terceiroPremio } = useBingo();
    
    const formatarParaMonetario = (valor) => {
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };
    if(terceiroPremio === null){
    }
    return (
        <>
        <div className='containerTop'>
            <div className='rodada'>
                <h1 className='text1'>rodada: teste 123</h1>
            </div>
            <div className='doacao'>
                <h1 className='text1'>DOAÇÃO: 1,00</h1>
            </div>
            <div className='acumulado'>
                <h1 className='text1'>Bolas para o Acumulado</h1>
            </div>
            </div>

        <div className='premio-container'>
            <div className='premio-item'
            style={{ backgroundColor: 'black' }}
            >
                <h2 className='text'>KUADRA     {premios.length > 0 ? formatarParaMonetario(premios[0]) : formatarParaMonetario(0)}</h2>
                <div className='bg'></div>
                <div className='blob'></div>
            </div>
            <div className='premio-item'
            style={{ backgroundColor: 'green' }}
            >
                <h2 className='text'>KINA {premios.length > 0 ? formatarParaMonetario(premios[1]) : formatarParaMonetario(0)}</h2>
                <div className='bg'></div>
                <div className='blob'></div>
            </div>
            <div className='premio-item'
            style={{ backgroundColor: 'green' }}
            >
                {/* <h2 className='text'>KENO</h2> */}
                <h2 className='text'> KENO {premios.length > 0 ? formatarParaMonetario(premios[2]) : formatarParaMonetario(0)}</h2>
                <div className='bg'></div>
                <div className='blob'></div>
            </div>
            <div className='premio-item'
            style={{ backgroundColor: 'green' }}
            >
                <h2 className='text'>Acumulado {premios.length > 0 ? formatarParaMonetario(premios[3]) : formatarParaMonetario(0)}</h2>
                <div className='bg'></div>
                <div className='blob'></div>
                
            </div>
        </div>
        </>
    );
}

export default PremioSorteio;
    
// import React from 'react';
// import './PremioSorteio.css'; 
// import { useBingo } from './../context/BingoContext';


// const PremioSorteio = () => {
//     const { premios, primeiroPremio, segundoPremio, terceiroPremio } = useBingo();
    
//     const formatarParaMonetario = (valor) => {
//         return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
//     };
//     if(terceiroPremio === null){
//     }
//     return (
//         <div className='premio-container'>
//             <div className='premio-item'
//             style={{ backgroundColor: 'green' }}
//             >
//                 <h2 className='text'>KUADRA     {premios.length > 0 ? formatarParaMonetario(premios[0]) : formatarParaMonetario(0)}</h2>
//                 <div className='bg'></div>
//                 <div className='blob'></div>
//             </div>
//             <div className='premio-item'
//             style={{ backgroundColor: 'green' }}
//             >
//                 <h2 className='text'>KINA {premios.length > 0 ? formatarParaMonetario(premios[1]) : formatarParaMonetario(0)}</h2>
//                 <div className='bg'></div>
//                 <div className='blob'></div>
//             </div>
//             <div className='premio-item'
//             style={{ backgroundColor: 'green' }}
//             >
//                 {/* <h2 className='text'>KENO</h2> */}
//                 <h2 className='text'> KENO {premios.length > 0 ? formatarParaMonetario(premios[2]) : formatarParaMonetario(0)}</h2>
//                 <div className='bg'></div>
//                 <div className='blob'></div>
//             </div>
//             <div className='premio-item'
//             style={{ backgroundColor: 'green' }}
//             >
//                 <h2 className='text'>Acumulado {premios.length > 0 ? formatarParaMonetario(premios[3]) : formatarParaMonetario(0)}</h2>
//                 <div className='bg'></div>
//                 <div className='blob'></div>
                
//             </div>
//         </div>
//     );
// }

// export default PremioSorteio;
