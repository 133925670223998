// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background: white;
    width: 100%;
    height: 100%;
  }

  
  #canvas-wrapper {
    /* position: absolute; */
    left: 50%;
    top: 50%;
    /* background: url(/assents/ball/blower.png); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* background-size: 140%; */
    /* background-position: -252px -113px; */
    /* transform: translateY(-50%) translateX(-50%); */
  }
  #canvas-wrapper:after {
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 87%;
    height: 87%;
    transform: translateY(-50%) translateX(-50%);
  }
  
  /*# sourceMappingURL=index.css.map */
  `, "",{"version":3,"sources":["webpack://./src/components/animacao/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;EACd;;;EAGA;IACE,wBAAwB;IACxB,SAAS;IACT,QAAQ;IACR,+CAA+C;IAC/C,4BAA4B;IAC5B,wBAAwB;IACxB,2BAA2B;IAC3B,2BAA2B;IAC3B,wCAAwC;IACxC,kDAAkD;EACpD;EACA;IACE,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,UAAU;IACV,WAAW;IACX,4CAA4C;EAC9C;;EAEA,oCAAoC","sourcesContent":["body {\n    background: white;\n    width: 100%;\n    height: 100%;\n  }\n\n  \n  #canvas-wrapper {\n    /* position: absolute; */\n    left: 50%;\n    top: 50%;\n    /* background: url(/assents/ball/blower.png); */\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: center;\n    /* background-size: 140%; */\n    /* background-position: -252px -113px; */\n    /* transform: translateY(-50%) translateX(-50%); */\n  }\n  #canvas-wrapper:after {\n    content: \"\";\n    border-radius: 100%;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 87%;\n    height: 87%;\n    transform: translateY(-50%) translateX(-50%);\n  }\n  \n  /*# sourceMappingURL=index.css.map */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
