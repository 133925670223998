import React, { useEffect } from 'react';
import BallPhysics from './BallPhysics'; 
import BallVersion from './BallVersao3'; 

// import BallAnimation from './BallAnimation'; // Componente para a animação com CreateJS
import './index.css';

function App() {
  
  return (
      <div id="canvas-wrapper">
      {/* <BallPhysics /> */}
      <BallVersion/>
      {/* <BallAnimation /> */}
    <canvas id="canvas" style={{ justifyContent: 'center'}}></canvas>
    </div>
  );
}

export default App;

