import React, { useRef, useEffect, useState } from 'react';
import { useBingo } from './../../context/BingoContext';

const SaiuPremio = ({ premiador }) => {
    const { numerosSorteados, primeiroPremio, segundoPremio, terceiroPremio } = useBingo();
    const [bola, setBola] = useState(0);
    const [mostrarPremio1, setMostrarPremio1] = useState(false);
    const [mostrarPremio2, setMostrarPremio2] = useState(false);
    const [mostrarPremio3, setMostrarPremio3] = useState(false);
    const [mostrar1, setMostrar1] = useState(true);
    const [mostrar2, setMostrar2] = useState(true);
    const [mostrar3, setMostrar3] = useState(true);

    useEffect(() => {
        if(mostrar1 === true){
          let timeout1;
        if (primeiroPremio && primeiroPremio.length > 0) {
          console.log('Você ganhou o primeiro prêmio!!');
          console.log(primeiroPremio);
          setMostrarPremio1(true);
          setTimeout(() => {
            setMostrar1(false);
          setMostrarPremio1(false);
          }, 5000);
          return () => clearTimeout(timeout1);
        }
      }
      }, [primeiroPremio, mostrar1]);
    
      useEffect(() => {
        if(mostrar1){
        if (segundoPremio && segundoPremio.length > 0) {
          console.log('Você ganhou o segundoPremio prêmio!!');
          console.log(segundoPremio);
          setMostrarPremio2(true);
          setTimeout(() => {
            setMostrar1(false);
          }, 5000);
        }
      }
        // Adicione outras verificações para o segundo e terceiro prêmio, se necessário
      }, [segundoPremio]);
    
      useEffect(() => {
        if(mostrar1){
    
        if (terceiroPremio && terceiroPremio.length > 0) {
          console.log('Você ganhou o primeiro prêmio!!');
          console.log(terceiroPremio);
          setMostrarPremio3(true);
          setTimeout(() => {
            setMostrar1(false);
          }, 5000);
        }
      }
      }, [terceiroPremio]);

      return (
        // <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', height: '100%', flexWrap: 'wrap', gap: '1px', padding: '1px', justifyContent: 'center' }}>
          {mostrarPremio1 && primeiroPremio.map((premio, index) => (
      <div
        key={index}
        style={{
          // position: 'absolute',
          zIndex: 2,
          height: '50%',
          width: '40%',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '10px',
          marginBottom: '2%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '20px',
          border: '1px solid #000',
          marginTop: '5%',
          margin: '1%'
        }}
      >
        <div style={{
          marginBottom: '5%'
        }}>
          <div>
        <h1>{premio.codigo} - {premio.nome}</h1>
          </div>
    </div>
        {[premio.linha1c, premio.linha2c, premio.linha3c].map((linha, linhaIndex) => (
          <div key={linhaIndex} style={{ display: 'flex' }}>
            {linha.map((numero, numeroIndex) => (
              <div
                key={`${linhaIndex}-${numeroIndex}`}
                style={{
                  width: '20%',
                  marginLeft: '0.5%',
                  height: '100%',
                  border: '2px solid #546E3D',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: numerosSorteados.includes(numero) ? 'green' : 'lightblue', // Muda a cor se o número estiver nos sorteados
                  fontSize: '2.2rem',
                }}
              >
                <h3>{numero < 10 ? `0${numero}` : numero}</h3>
              </div>
            ))}
          </div>
        ))}
      </div>
    ))}
          {/* <canvas ref={canvasRef} width={width} height={height} /> */}
        </div>
      )
};

export default SaiuPremio;
