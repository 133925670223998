import React, { createContext, useContext, useState } from 'react';

const BingoContext = createContext();

export const useBingo = () => useContext(BingoContext);

export const BingoProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [numerosSorteados, setNumerosSorteados] = useState([]);
  const [rankingDez, setRankingDez] = useState([]);
  const [premios, setPremios] = useState([]);
  const [primeiroPremio, setPrimeiroPremio] = useState([]);
  const [segundoPremio, setSegundoPremio] = useState([]);
  const [terceiroPremio, setTerceiroPremio] = useState([]);
  const [anuncios, setAnuncios] = useState([]);




  const updateBingoData = (data) => {
    setMessage(data.message);
    setNumerosSorteados(data.numerosSorteados);
    setRankingDez(data.rankingDez);
    setPremios(data.premios);
    setPrimeiroPremio(data.primeiroPremio);
    setSegundoPremio(data.segundoPremio);
    setTerceiroPremio(data.terceiroPremio);
    setAnuncios(data.anuncios);


  };

  return (
    <BingoContext.Provider value={{ message, numerosSorteados, rankingDez,premios, updateBingoData,primeiroPremio, segundoPremio, terceiroPremio, anuncios}}>
      {children}
    </BingoContext.Provider>
  );
};
