// import styled from 'styled-components';

// export const Container = styled.div`
//   @media only screen and (max-width: 768px) {
  
//   }
// `;


// import React, { useState } from 'react';
// import styled from 'styled-components';
/* eslint-disable no-restricted-globals */

  import React, { useState } from 'react';
  import styled from 'styled-components';

  export const Container = styled.div`
    @media only screen and (max-width: 768px) {
      transform: rotate(90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  `;


  const RotateButton = styled.button`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
    padding: 10px 20px;
    background-color: blue;
    color: #blue;
    border: none;
    cursor: pointer;
  `;

  const ScreenRotationExample = () => {
    const [isRotationEnabled, setIsRotationEnabled] = useState(true);

    const toggleRotation = () => {
      if (isRotationEnabled) {
        screen.orientation.lock('portrait').catch(err => console.error('Failed to lock orientation', err));
      } else {
        screen.orientation.unlock();
      }
      setIsRotationEnabled(prevState => !prevState);
    };

    return (
      <>
        <RotateButton onClick={toggleRotation}>
          {isRotationEnabled ? 'Bloquear Rotação' : 'Desbloquear Rotação'}
        </RotateButton>
        <Container>
          {/* Conteúdo da sua aplicação aqui */}
          <div>
            <h1>Exemplo de Conteúdo</h1>
            <p>Este é um exemplo de conteúdo dentro do componente Container.</p>
          </div>
        </Container>
      </>
    );
  };

  export default ScreenRotationExample;


