// App.js
import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
    BrowserRouter,
    Routes, // instead of "Switch"
    Route,
  } from "react-router-dom";
import Sorteio from './pages/sorteio';
import { BingoProvider } from './context/BingoContext';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import PageNotFound from './pages/PageNotFound';

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'app-key',
    wsHost: '127.0.0.1',
    // wsHost: process.env.REACT_APP_API_URL_WEB,  
    // wsHost: `${process.env.REACT_APP_API_URL_WEB}`,
    // wsHost: '192.168.15.2',
    // wsHost: 'https://4r86v202-8000.brs.devtunnels.ms',
    wsPort: 6001,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    // cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    cluster: 'mt1',
});

const App = () => {
    return (
        <BingoProvider>
<BrowserRouter>
<Routes>
  <Route path="/" element={<Sorteio />} />
  <Route path="*" element={<PageNotFound />} />

</Routes>
</BrowserRouter>
</BingoProvider>
    );
}

export default App;
