import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Importe isso se estiver usando React Router para lidar com rotas

const Page404Wrapper = styled.section`
  padding: 40px 0; 
  background: #fff; 
  font-family: 'Arvo', serif; 
`;

const BackgroundImage = styled.div`
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif); 
  height: 400px; 
  background-position: center; 
`;

const Title = styled.h1`
  font-size: 80px;
`;

const Message = styled.h3`
  font-size: 80px;
`;

const Link404 = styled(Link)`
  color: #fff!important; 
  padding: 10px 20px; 
  background: #39ac31; 
  margin: 20px 0; 
  display: inline-block; 
`;

function PageNotFound() {
  return (
    <Page404Wrapper>
      <div className="container">
        <div className="row">  
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <BackgroundImage>
                <Title className="text-center">ops 404...</Title>
              </BackgroundImage>
              <div className="contant_box_404">
                {/* <Message className="h2">Nossa equipe esta trabalhando nesse erro</Message> */}
                <p>Nossa equipe esta trabalhando nisso!!</p>
                <Link404 to="/" className="link_404">Go to Home</Link404> {/* Certifique-se de ajustar o link conforme necessário */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page404Wrapper>
  );
}

export default PageNotFound;
