import React, { useRef, useEffect, useState } from 'react';
import { useBingo } from './../../context/BingoContext';


const BallVersion = () => {
  const { numerosSorteados, primeiroPremio, segundoPremio, terceiroPremio, } = useBingo();
  const [bola, setBola] = useState(0);
  const [mostrarPremio1, setMostrarPremio1] = useState(false);
  const [mostrarPremio2, setMostrarPremio2] = useState(false);
  const [mostrarPremio3, setMostrarPremio3] = useState(false);
  const [mostrar1, setMostrar1] = useState(true);
  const [mostrar2, setMostrar2] = useState(false);
  const [mostrar3, setMostrar3] = useState(false);

  const canvasRef = useRef(null);

  const playAudio = () => {
    setTimeout(() => {
      const audio = new Audio(`/assents/audio/${bola}.mp3`);
      audio.play().then(() => {
        // console.log(`Áudio ${bola}.mp3 reproduzido com sucesso.`);
      }).catch(error => {
        console.error(`Erro ao reproduzir o áudio ${bola}.mp3:`, error);
      });
    }, 1000);
  }

  useEffect(() => {
    playAudio();
  }, [bola]);

  const formatarParaMonetario = (valor) => {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

  useEffect(() => {
    if(mostrar1 === true){
      let timeout1;
    if (primeiroPremio && primeiroPremio.length > 0) {
      setMostrarPremio1(true);
      setTimeout(() => {
        const audio = new Audio(`/assents/audio/quadra.mp3`);
        audio.play().then(() => {
        }).catch(error => {
          console.error(`Erro ao reproduzir o áudio quadra.mp3:`, error);
        });
      }, 2000);
      setTimeout(() => {
        setMostrar1(false);
        setMostrar2(true)
      setMostrarPremio1(false);
      }, 5000);
      return () => clearTimeout(timeout1);
    }
  }
  }, [primeiroPremio, mostrar1]);

  useEffect(() => {
    if(mostrar2 === true){
      let timeout2;
    if (segundoPremio && segundoPremio.length > 0) {
      setMostrarPremio2(true);
      setTimeout(() => {
        const audio = new Audio(`/assents/audio/quina.mp3`);
        audio.play().then(() => {
        }).catch(error => {
          console.error(`Erro ao reproduzir o áudio quadra.mp3:`, error);
        });
      }, 2000);
      setTimeout(() => {
        setMostrar2(false);
        setMostrar3(true);
      setMostrarPremio2(false);
      }, 5000);
      return () => clearTimeout(timeout2);
    }
  }
  }, [mostrar2, segundoPremio]);

  useEffect(() => {
    if(mostrar3 === true){
      let timeout3;
    if (terceiroPremio && terceiroPremio.length > 0) {
      setMostrarPremio3(true);
      setTimeout(() => {
        const audio = new Audio(`/assents/audio/bingo.mp3`);
        audio.play().then(() => {
        }).catch(error => {
          console.error(`Erro ao reproduzir o áudio quadra.mp3:`, error);
        });
      }, 5000);
      setTimeout(() => {
        setMostrar3(false);
        setMostrarPremio3(false);
      }, 5000);
      return () => clearTimeout(timeout3);
    }
  }
    // Adicione outras verificações para o segundo e terceiro prêmio, se necessário
  }, [mostrar3, terceiroPremio]);

  useEffect(() => {
    // Pegar o último número sorteado
    const ultimoNumeroSorteado = numerosSorteados[numerosSorteados.length - 1];
    // Verificar se o último número existe e é um número válido
    if (ultimoNumeroSorteado && !isNaN(ultimoNumeroSorteado)) {
      setBola(ultimoNumeroSorteado);
    }
  }, [numerosSorteados]);

  const width = 650;
  const height = 350;
  const ball = {
    position: { x: width / 2, y: 0 },
    // position: { x: 0, y: 0 },
    velocity: { x: 10, y: 0 },
    mass: 1.9,
    radius: 95,
    restitution: -1.7
  };
  const Cd = 0.47;
  const rho = 1.22;
  const A = Math.PI * ball.radius * ball.radius / (10000);
  const ag = 9.81;
  const frameRate = 1 / 40;
  const frameDelay = frameRate * 1000;
  let loopTimer;

  const cores = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff'];

  const escolherCorAleatoria = () => {
    return cores[Math.floor(Math.random() * cores.length)];
  };
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    // ctx.fillStyle = 'red';
    ctx.fillStyle = escolherCorAleatoria();
    ctx.strokeStyle = '#000000';

    const setup = () => {
      loopTimer = setInterval(loop, frameDelay);
    };

    const loop = () => {
      let Fx = -0.5 * Cd * A * rho * ball.velocity.x * ball.velocity.x * ball.velocity.x / Math.abs(ball.velocity.x);
      let Fy = -0.5 * Cd * A * rho * ball.velocity.y * ball.velocity.y * ball.velocity.y / Math.abs(ball.velocity.y);

      Fx = (isNaN(Fx) ? 0 : Fx);
      Fy = (isNaN(Fy) ? 0 : Fy);

      const ax = Fx / ball.mass;
      const ay = ag + (Fy / ball.mass);

      ball.velocity.x += ax * frameRate;
      ball.velocity.y += ay * frameRate;

      ball.position.x += ball.velocity.x * frameRate * 100;
      ball.position.y += ball.velocity.y * frameRate * 100;


      if (ball.position.y > height - ball.radius) {
        ball.velocity.y *= ball.restitution;
        ball.position.y = height - ball.radius;
      }


      if (ball.position.y < ball.radius) {
        ball.velocity.y *= ball.restitution;
        ball.position.y = ball.radius;
      }

      if (ball.position.x > width - ball.radius) {
        ball.velocity.x *= ball.restitution;
        ball.position.x = width - ball.radius;
      }

      if (ball.position.x < ball.radius) {
        ball.velocity.x *= ball.restitution;
        ball.position.x = ball.radius;
      }

      ctx.clearRect(0, 0, width, height);
      ctx.save();

      // const gradient = ctx.createRadialGradient(ball.position.x, ball.position.y, 0, ball.position.x, ball.position.y, ball.radius);
      // gradient.addColorStop(0, '#ff6666'); // Cor mais escura no centro
      // gradient.addColorStop(1, '#ff9999'); // Cor mais clara na borda
      // ctx.fillStyle = gradient;

       // Desenhar a bola com os estilos CSS desejados
    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.arc(ball.position.x, ball.position.y, ball.radius, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.lineWidth = 55; // Defina a largura da borda
    ctx.strokeStyle = '#330066'; // Defina a cor da borda
    // ctx.fillStyle = escolherCorAleatoria();

    ctx.stroke();

    // Adicionar o número no centro da bola
    ctx.fillStyle = '#000';
    ctx.textAlign = 'center';
    ctx.font = 'bold ' + ball.radius + 'px serif';
    ctx.fillText(bola, ball.position.x, ball.position.y + ball.radius / 3);
  
      // ctx.translate(ball.position.x, ball.position.y);
      // ctx.beginPath();
      // ctx.arc(0, 0, ball.radius, 0, Math.PI * 2, true);
      // ctx.closePath();
      // ctx.fill();
      // ctx.fillStyle = 'white';
      // ctx.textAlign = 'center';
      // ctx.font = 'bold ' + ball.radius + 'px serif';
      // ctx.fillText(bola, 0, 30);
      // ctx.restore();
    };

    setup();

    return () => clearInterval(loopTimer);
  }, [bola]);

  return (
    <div style={{ display: 'flex', height: '100%', flexWrap: 'wrap', gap: '1px', padding: '1px', justifyContent: 'center' }}>
      {mostrarPremio1 && primeiroPremio.map((premio, index) => (
  <div
    key={index}
    style={{
      // position: 'absolute',
      zIndex: 2,
      height: '50%',
      width: '40%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '10px',
      marginBottom: '2%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      border: '1px solid #000',
      marginTop: '5%',
      margin: '1%'
    }}
  >
    <div style={{
      marginBottom: '5%'
    }}>
      <div>
    <h1>{premio.codigo} - {premio.nome}</h1>
      </div>
</div>
    {[premio.linha1c, premio.linha2c, premio.linha3c].map((linha, linhaIndex) => (
      <div key={linhaIndex} style={{ display: 'flex' }}>
        {linha.map((numero, numeroIndex) => (
          <div
            key={`${linhaIndex}-${numeroIndex}`}
            style={{
              width: '20%',
              marginLeft: '0.5%',
              height: '100%',
              border: '2px solid #546E3D',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: numerosSorteados.includes(numero) ? 'green' : 'lightblue', // Muda a cor se o número estiver nos sorteados
              fontSize: '2.2rem',
            }}
          >
            <h3>{numero < 10 ? `0${numero}` : numero}</h3>
          </div>
        ))}
        
      </div>
    ))}
    <div>
    <h1>{premio.pago > 0 ? formatarParaMonetario(premio.pago) : formatarParaMonetario(0)}</h1>
      </div>
  </div>
))}
  {mostrarPremio2 && segundoPremio.map((premio, index) => (
  <div
    key={index}
    style={{
      // position: 'absolute',
      zIndex: 2,
      height: '50%',
      width: '40%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '10px',
      marginBottom: '2%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      border: '1px solid #000',
      marginTop: '5%',
      margin: '1%'
    }}
  >
    <div style={{
      marginBottom: '5%'
    }}>
      <div>
    <h1>{premio.codigo} - {premio.nome}</h1>
      </div>
</div>
    {[premio.linha1c, premio.linha2c, premio.linha3c].map((linha, linhaIndex) => (
      <div key={linhaIndex} style={{ display: 'flex' }}>
        {linha.map((numero, numeroIndex) => (
          <div
            key={`${linhaIndex}-${numeroIndex}`}
            style={{
              width: '20%',
              marginLeft: '0.5%',
              height: '100%',
              border: '2px solid #546E3D',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: numerosSorteados.includes(numero) ? 'green' : 'lightblue', // Muda a cor se o número estiver nos sorteados
              fontSize: '2.2rem',
            }}
          >
          <h3>{numero < 10 ? `0${numero}` : numero}</h3>
          </div>
        ))}
        
      </div>
    ))}
    <div>
    <h1>{premio.pago > 0 ? formatarParaMonetario(premio.pago) : formatarParaMonetario(0)}</h1>
      </div>
  </div>
))}
  {mostrarPremio3 && terceiroPremio.map((premio, index) => (
  <div
    key={index}
    style={{
      // position: 'absolute',
      zIndex: 2,
      height: '50%',
      width: '40%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '10px',
      marginBottom: '2%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      border: '1px solid #000',
      marginTop: '5%',
      margin: '1%'
    }}
  >
    <div style={{
      marginBottom: '5%'
    }}>
      <div>
    <h1>{premio.codigo} - {premio.nome}</h1>
      </div>
</div>
    {[premio.linha1c, premio.linha2c, premio.linha3c].map((linha, linhaIndex) => (
      <div key={linhaIndex} style={{ display: 'flex' }}>
        {linha.map((numero, numeroIndex) => (
          <div
            key={`${linhaIndex}-${numeroIndex}`}
            style={{
              width: '20%',
              marginLeft: '0.5%',
              height: '100%',
              border: '2px solid #546E3D',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: numerosSorteados.includes(numero) ? 'green' : 'lightblue', // Muda a cor se o número estiver nos sorteados
              fontSize: '2.2rem',
            }}
          >
            <h3>{numero < 10 ? `0${numero}` : numero}</h3>
          </div>
        ))}
        
      </div>
    ))}
    <div>
    <h1>{premio.pago > 0 ? formatarParaMonetario(premio.pago) : formatarParaMonetario(0)}</h1>
      </div>
  </div>
))}


      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  )

  // return <canvas ref={canvasRef} width={width} height={height} />;
}

export default BallVersion;
