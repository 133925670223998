import React, { useState } from 'react';
import './Ranking.css';
import { useBingo } from './../context/BingoContext';

const CatListItem = ({ cat, rank }) => {
  const { rankingDez } = useBingo();

  const colors = ["#d6cd1e", "#bbbbbb", "#d6a21e"];

  const [colorOrder] = useState(colors[rank]);

  const handlePointIncrement = () => {
    cat.points++;
  };

  const handlePointDecrement = () => {
    cat.points--;
  };
  return (
    <li className="cat-item">
      <h4 style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: "1 0 56%", marginRight: "1%", overflow: 'hidden', whiteSpace: 'nowrap', backgroundColor: ' white' }}>
          {cat.codigo}-{cat.nome}
        </div>
        <div style={{ flex: "1 0 45%" }}>
          <div className="bordered-numbers">
            {cat.falta.slice(0, 7).map((numero, index) => (
              <span style={{ backgroundColor: 'white' }} key={index}>{numero < 10 ? `0${numero}` : numero}</span>
            ))}
          </div>
        </div>
      </h4>
    </li>
  );
};

const Ranking = () => {
  const catRank = [
    { r: 2, c: "#d6a21e" },
    { r: 0, c: "#d6cd1e" },
    { r: 1, c: "#bbbbbb" }
  ];
  const { rankingDez, setRankingDez } = useBingo();

  const handlePointIncrement = (index) => {
    const updatedCats = [...rankingDez];
    updatedCats[index].points++;
    setRankingDez(updatedCats);
  };

  const handlePointDecrement = (index) => {
    const updatedCats = [...rankingDez];
    updatedCats[index].points--;
    setRankingDez(updatedCats);
  };

  const allCats = rankingDez.slice().sort((a, b) => b.points - a.points);
  const topThreeCats = allCats.slice(0, 3).map((cat, index) => ({ ...cat, index }));

  return (
    <div className="container" id="app">
      <div className="board">
        <ul>
          {allCats.map((cat, index) => (
            <CatListItem key={index} cat={cat} rank={index} />
          ))}
        </ul>
      </div>


    </div>
  );
};

export default Ranking;
//115